import { useEffect, useState } from "react";
import {get} from "../../../Libraries/Fetch";
import { useSelector,useDispatch } from "react-redux";
import { Oval } from "react-loader-spinner";
import icon_ticket from "../../../assets/logo/icon_ticket.png";
import icon_ticket_complete from "../../../assets/logo/icon_ticket_complete.png";
import icon_ticket_failed from "../../../assets/logo/icon_ticket_failed.png";
import icon_ticket_assigned from "../../../assets/logo/icon_ticket_assigned.png";
import NoTicket from "./Components/NoTicket";
import Card from "./Components/Card";
import { setPageName } from "../../../store";
import { faWrench,faUserCheck, faUserAlt } from "@fortawesome/free-solid-svg-icons";
export default function App() {
  const token=useSelector(state=>state.session.token);
  const dispatch=useDispatch();
  const [data,setData]=useState({
    'jml_assigned':0,
    'jml_complete':0,
    'jml_failed':0,
    'jml_user': 0,
    'jml_customer': 0,
    'jml_teknisi': 0,
    'jml_tiket':0
  });
  const [loading,setLoading]=useState(true);
  const readData=async()=>{
    setLoading(true);
    let req=await get("Admin",token)
    setData(req.data.data);
    setLoading(false);
  }
  useEffect(()=>{
    dispatch(setPageName("Home"));
    readData();
  },[])
  return (
    <>
      {
        loading
        ?
        <div className="w-100 display-flex justify-content-center align-items-center">
            <Oval color="#007bff" height={50} width={50} />
        </div>
        :
        <>
        {
          data.jml_tiket>0
          ?
          <>
            <div className="row justify-content-space-arround m4">
              <Card
                count={data.jml_customer}
                label="Customers"
                to="Customer"
                FAIcon={faUserCheck}
                btn_color="btn-success"
              />
              <Card
                count={data.jml_user}
                label="Users"
                to="User"
                FAIcon={faUserAlt}
                btn_color="btn-primary"
              />
            </div>
            <div className="row justify-content-space-arround m4">
              <Card
                count={data.jml_teknisi}
                label="Techinican"
                to="Teknisi"
                FAIcon={faWrench}
                btn_color="btn-success"
              />
              <Card
                count={data.jml_tiket}
                label="Ticket"
                to="Tickets"
                icon={icon_ticket}
                btn_color="btn-success"
              />
            </div>
            <div className="row justify-content-space-arround m4">
              <Card
                count={data.jml_complete}
                label="Complete"
                to="Tickets"
                icon={icon_ticket_complete}
                btn_color="btn-primary"
              />
              <Card
                count={data.jml_assigned}
                label="Assigned"
                to="Tickets"
                icon={icon_ticket_assigned}
                btn_color="btn-purple"
              />
            </div>
            <div className="row justify-content-space-arround m4">
              <Card
                count={data.jml_failed}
                label="Failed"
                to="Tickets"
                icon={icon_ticket_failed}
                btn_color="btn-danger"
              />
            </div>
          </>
          :
          <NoTicket/>

        }
        </>

      }
    </>

  )
}