import { useState, useEffect, useRef, forwardRef, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useParams } from 'react-router-dom';
import { Textinput } from '../../../Components';
import { PasswordInput } from '../../../Components';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { setToken, setType, setVerified, setUserData } from "./../../../store";
import { useDispatch } from 'react-redux';
import BigLogo from "../../../assets/img/helpdesk logo2.png";
import Google from "../../../assets/logo/Google.png";
import { post,get } from '../../../Libraries/Fetch';
import { Oval } from 'react-loader-spinner';
export default function App() {
  let captcha=useRef(null);
  const initialErrorMessage = {
    username: '',
    password: '',
    captcha: '',
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const dispatch=useDispatch();
  let [googleLink,setGoogleLink]=useState('');
  let username = useRef(null);
  let password = useRef(null);
  let captchaElement=useRef(null);
  const [loading,setLoading]=useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(true);
  const params=useParams();
  const doLogin = async(e) => {
    e.preventDefault();
    if(loading){return "";}
    setErrorMessage(initialErrorMessage)
    setLoading(true);

    let fr=new FormData();
    fr.append('username',username.current.value);
    fr.append('password', password.current.value);
    fr.append('captcha', captcha.current);
    const req = await post('', fr);
    if(req.data.status=="error"){
      if (req.data.message.username){
        setErrorMessage((n)=>{return{...n,username:req.data.message.username[0]}});
      }
      if (req.data.message.password) {
        setErrorMessage((n) => { return { ...n, password: req.data.message.password[0] } });
      }
      if (req.data.message.captcha) {
        setErrorMessage((n) => { return { ...n, captcha: req.data.message.captcha[0] } });
      }
      captchaElement.current.reset();
    }
    else{
      localStorage.setItem('helpdesk_session_token', req.data.data.token);
      alert('Login berhasil');

      window.location.reload();
    }
    setLoading(false);
  }
  const get_google_link = async (e) => {
    const req = await get('');
    setGoogleLink(req.data.data);
  }
  const handleCaptcha = (e) => {
    captcha.current=e;
  }
  const checkToken=async ()=>{
    setLoadingGoogle(true);
    if(params.token){
      const req = await get('verify', params.token);
      if (req.data.status != "error") {
        localStorage.setItem('helpdesk_session_token', params.token);
        window.location.href="/";
      }
    }
    else{
      get_google_link();
    }
    setLoadingGoogle(false);
  }
  useEffect(() => {
    checkToken();
  },[])
  return (
    <div className='display-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      {
        loadingGoogle?
        <Oval color="#007bff" height={50} width={50} />
        :
        <form onSubmit={doLogin} className='c-xs-90 c-sm-90 c-md-40 c-lg-40 c-xl-30 border-rad-5 p2' style={{ boxShadow: "1px 1px 10px 3px" }}>
        <div className='display-flex w-100 justify-content-center'>
          <img src={BigLogo} style={{ width: '250px' }} />
        </div>
        <div className='display-flex justify-content-center'>
          <div className='mt2 mb2 textcolor-black' style={{ fontWeight: 'bold', fontSize: '24pt' }}>Log in</div>
        </div>
        <div className='display-flex justify-content-center'>
          <span>Dont have account yet ? <Link to="/sign-up"><span className='textcolor-primary'>Sign Up</span></Link>  </span>
        </div>

        <Textinput Ref={username} label="Username" placeholder="username"
        icon={faUserCircle} errorMessage={errorMessage.username} />
        <PasswordInput
          Ref={password}
          label="Password"
          errorMessage={errorMessage.password}
          secondLabel={<Link to="/reset"><a className="textcolor-primary">Forgot Password?</a></Link>}
          placeholder="password" />
        <ReCAPTCHA
          ref={captchaElement}
          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
          onChange={handleCaptcha}
        />
        {errorMessage.captcha && <div className='display-flex justify-content-center mt4 mb4'>
          <div className='textcolor-danger'>Recaptcha error</div>
        </div>}
        <div className='display-flex justify-content-center mt4 mb4'>
          <button disabled={loading?true:false} className='btn display-flex justify-content-center align-items-center btn-primary textcolor-black border-rad-10 w-100'>
          {loading ? <Oval
            height={20}
            width={20}
            color="#FFFFFF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#FFFFFF"
            strokeWidth={2}
            strokeWidthSecondary={2}

            /> : "Login"}
          </button>
        </div>
        <a href={googleLink} className='display-flex justify-content-center mb4'>
          <button type="button" className='display-flex justify-content-center align-items-center btn btn-o-secondary textcolor-black border-rad-10 w-100'>
            <img src={Google} />Login with google
          </button>
        </a>
      </form>}
    </div>


  )
}