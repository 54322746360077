import { useEffect,useState,useRef } from "react";
import {get} from "../../../Libraries/Fetch";
import { useDispatch,useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import Options from "./Components/Options";
import PriorityBadge from "./Components/PriorityBadge";
import ResponseTimeBadge from "./Components/ResponseTimeBadge";
import {StatusBadge} from "../../../Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import ModalAdd from "./Components/ModalAdd";
import ModalEdit from "./Components/ModalEdit";
import ModalConfirm from "./Components/ModalConfirm";
import ModalDelete from "./Components/ModalDelete";
import ModalFeedback from "./Components/ModalFeedback";
import ModalComplain from "./Components/ModalComplain";
import ModalDetail from "./Components/ModalDetail";
import { setPageName } from "../../../store";
import { SelectInput,Textinput } from "../../../Components";
export default function App() {
  const token=useSelector(state=>state.session.token);
  const dispatch=useDispatch();
  const [showModalAdd,setShowModalAdd]=useState(false);
  const [showModalComplain, setShowModalComplain] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [loading,setLoading]=useState(true);
  const [pageComponent,setPageComponent]=useState([])
  const [data,setData]=useState({
    data:[],
    maxPage:1
  });
  const ModalData={
    tableData:{
      limit:useRef(10),
      search:useRef(null)
    },
    add:{
      topik_problem:useRef(null),
      infrastruktur: useRef(null),
      deskripsi_problem:useRef(null),
      prioritas: useRef(null),
      foto:useRef([])
    },
    feedback: {
      id_tiket: useRef(null),
      ket: useRef(null),
      rating:useRef(0),
      foto: useRef([]),
      oldFoto: useRef([])
    },
    edit: {
      id_tiket: useRef(null),
      infrastruktur: useRef(null),
      topik_problem: useRef(null),
      deskripsi_problem: useRef(null),
      prioritas: useRef(null),
      foto: useRef([]),
      oldFoto: useRef([])
    },
    detail: {
      id_tiket: useRef("")
    },
    complain: {
      id_tiket: useRef(null),
      ket: useRef(null),
      foto: useRef([])
    },
    confirm: {
      id_tiket: useRef(null)
    }
  }
  const page=useRef(1);
  const readData=async()=>{
    setLoading(true);
    let q = ModalData.tableData.search.current.value;
    let req="";
    if(q!=""){
      req = await get("Customer/Tiket/search/" + page.current + "/" + ModalData.tableData.limit.current + "?q=" + q, token);
    }
    else{
      req = await get("Customer/Tiket/" + page.current + "/" + ModalData.tableData.limit.current, token);
    }

    setData(req.data.data);
    setLoading(false);
    setPageComponent((n) => {
      let objek = {
        minPage: 1,
        maxPage: 1,
      };
      let pageComponentBaru=[];
      objek.minPage = page.current - 2;
      if (objek.minPage <= 0) {
        objek.minPage = 1;
      }
      if(page.current==1){
        objek.maxPage = page.current + 4;
      }
      else if(page.current == 2) {
        objek.maxPage = page.current + 3;
      }
      else if (page.current >2) {
        objek.maxPage = page.current + 2;
      }
      if (objek.maxPage > req.data.data.maxPage) {
        objek.maxPage = req.data.data.maxPage;
      }
      for (let i = objek.minPage; i <= objek.maxPage; i++) {
        if(i==page.current){
          pageComponentBaru.push(
            <button key={i} className="btn-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
        else{
          pageComponentBaru.push(
            <button key={i} onClick={async()=>{page.current=i;await readData()}} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
      }
      return pageComponentBaru;
    })
    
  }
  const readOne = async (id_tiket) => {
    let req = await get("Customer/Tiket/readOne/" + id_tiket, token);
    setShowModalEdit(true);
    setTimeout(()=>{
      ModalData.edit.id_tiket.current = id_tiket;
      ModalData.edit.topik_problem.current.value = req.data.data.topik_problem;
      ModalData.edit.infrastruktur.current.value = req.data.data.infrastruktur;
      ModalData.edit.prioritas.current.value = req.data.data.id_prioritas;
      ModalData.edit.oldFoto.current = req.data.data._foto;
      ModalData.edit.deskripsi_problem.current.data.set(req.data.data.isi_problem);
    },250);
  }
  const handleDetail = (id_tiket) => {
    ModalData.detail.id_tiket.current = id_tiket;
    setShowModalDetail(true);
  }
  const showComplain=(id_tiket)=>{
    ModalData.complain.id_tiket.current=id_tiket;
    setShowModalComplain(true);
  }
  const readFeedback = async (id_tiket) => {
    let req = await get("Customer/Tiket/feedback/" + id_tiket, token);
    ModalData.feedback.id_tiket.current = id_tiket;
    setShowModalFeedback(true);
    if(req.data.data!=""){
      ModalData.feedback.ket.current.data.set(req.data.data.ket);
      ModalData.feedback.rating.current = req.data.data.rating;
      ModalData.feedback.oldFoto.current = req.data.data._foto;
    }
    else{
      ModalData.feedback.ket.current.data.set("");
      ModalData.feedback.rating.current = 0;
      ModalData.feedback.oldFoto.current = [];
    }
  }
  const setConfirm=(id_tiket)=>{
    ModalData.confirm.id_tiket.current=id_tiket;
    setShowModalConfirm(true);
  }
  const setDelete = (id_tiket) => {
    ModalData.confirm.id_tiket.current = id_tiket;
    setShowModalDelete(true);
  }
  useEffect(()=>{
    readData();
    dispatch(setPageName("My Ticket"));
  },[])
  return (
    <div className="m4">
      {showModalAdd && <ModalAdd
        showModal={showModalAdd}
        setShowModal={setShowModalAdd}
        Ref={ModalData.add}
        saveCallback={()=>{
          page.current=1;
          readData();
        }}
      />}
      <ModalDetail
        showModal={showModalDetail}
        setShowModal={setShowModalDetail}
        Ref={ModalData.detail}
      />
      {showModalComplain && <ModalComplain
        showModal={showModalComplain}
        setShowModal={setShowModalComplain}
        Ref={ModalData.complain}
        saveCallback={() => {
          readData();
        }}
      />}
      {showModalEdit && <ModalEdit
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        Ref={ModalData.edit}
        saveCallback={() => {
          readData();
        }}
      />}
      <ModalFeedback
        showModal={showModalFeedback}
        setShowModal={setShowModalFeedback}
        Ref={ModalData.feedback}
        saveCallback={() => {
          readData();
        }}
      />
      <ModalConfirm
        showModal={showModalConfirm}
        setShowModal={setShowModalConfirm}
        Ref={ModalData.confirm}
        saveCallback={() => {
          readData();
        }}
      />
      <ModalDelete
        showModal={showModalDelete}
        setShowModal={setShowModalDelete}
        Ref={ModalData.confirm}
        saveCallback={() => {
          readData();
        }}
      />
      <>
        <div className="w-100 display-flex">
          <button type="button" onClick={() => { setShowModalAdd(true) }} className="btn-primary border-rad-5">
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        <div className="row w-100 mt2 justify-content-space-between align-items-center">
          <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
            <div className="display-flex align-items-center">
              <span>Show</span>
              <SelectInput
                onChange={async (e) => {
                  page.current = 1;
                  ModalData.tableData.limit.current = e.target.value;
                  await readData();
                }}
                className="display-block ml2 mr2"
                style={{ width: "45px" }}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </SelectInput>
              <span>Item</span>
            </div>
          </div>
          <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
            <Textinput placeholder="search"
              Ref={ModalData.tableData.search}
              onKeyDown={async (e) => {
                let q = ModalData.tableData.search.value
                if (e.key == "Enter") {
                  page.current = 1;
                  await readData();
                }
              }}
              icon={faSearch} />
          </div>
        </div>
        {
          loading
            ?
            <div className="row justify-content-center mt2">
              <Oval color="#007bff" height={50} width={50} />
            </div>
            :
            <div className="row mt2">
              <div className="w-100 mt2 table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Topik</th>
                      <th>Prioritas</th>
                      <th>Assign Date</th>
                      <th>Status</th>
                      <th>Response time</th>
                      <th>Control</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.topik_problem}</td>
                            <td>
                              <PriorityBadge priority={item.prioritas} />
                            </td>
                            <td>{item._tgl_assign == undefined ? "-" : item._tgl_assign}</td>
                            <td>
                              <StatusBadge justify="center" status={item._status} />
                            </td>
                            <td>
                              <ResponseTimeBadge response={item._response} />
                            </td>
                            <td>
                              <Options
                                item={item}
                                readOne={readOne}
                                handleDetail={handleDetail}
                                setConfirm={setConfirm}
                                readFeedback={readFeedback}
                                showComplain={showComplain}
                                setDelete={setDelete}
                                deleteCallback={() => {
                                  page.current = 1;
                                  readData();
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
        }
        <div className="display-flex justify-content-flex-end w-100">
          <button onClick={()=>{page.current=1;readData();}} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {"|<"}
          </button>
          <button onClick={() => { page.current -= 1; if (page.current <= 0) { page.current = 1 } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {"<<"}
          </button>
          {pageComponent}
          <button onClick={() => { page.current += 1; if (page.current > data.maxPage) { page.current = data.maxPage } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {">>"}
          </button>
          <button onClick={() => { page.current = data.maxPage;readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {">|"}
          </button>
        </div>
      </>
    </div>
  )
}