import PriorityBadge from "./PriorityBadge";
import ResponseTimeBadge from "./ResponseTimeBadge";
import StatusBadge from "./StatusBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
export default ({data})=>{
  return (
    <div style={{minHeight:"450px"}} className="overflow-auto mb-md-2 mb-lg-0 mb-sm-2 mb-xs-2 c-xs-100 c-sm-100 c-md-100 c-lg-40 c-xl-30 border-solid-primary-1 p3 border-rad-5">
      <button onClick={()=>{window.history.go(-1)}} type="button" className="mt1 btn-o-danger border-rad-5 textcolor-danger">
        <FontAwesomeIcon icon={faBackward}/>
      </button>
      <div className="mt3">Topik</div>
      <div className="textcolor-black mt1" style={{ fontWeight: 'bold' }}>
        {data.topik_problem}
      </div>
      <div className="mt3">Infrastruktur</div>
      <div className="textcolor-black mt1" style={{ fontWeight: 'bold' }}>
        {data.infrastruktur}
      </div>
      <div className="mt1">Prioritas</div>
      <div className="textcolor-black mt1">
        <PriorityBadge priority={data?.prioritas} />
      </div>
      <div>Tgl. Assign</div>
      <div className="textcolor-black mt1" style={{ fontWeight: 'bold' }}>
        {data._tgl_assign!=null ? data._tgl_assign : "-"}
      </div>
      <div className="mt1">Status</div>
      <div className="textcolor-black mt1">
        <StatusBadge status={data._status} />
      </div>
      <div className="mt1">Response Time</div>
      <div className="textcolor-black mt1">
        <ResponseTimeBadge response={data._response != null ? data._response:"-"} />
      </div>
      <div className="mt1">Teknisi Yang Ditugaskan</div>
      <div className="textcolor-black mt1" style={{ fontWeight: 'bold' }}>
        {data.nama != null ? data.nama:"-"}
      </div>

    </div>
  )
}