import Home from "../Pages/Admin/Home";
import Tickets from "../Pages/Admin/Tickets";
import Progress from "../Pages/Admin/Progress";
import Personal from "../Pages/Admin/Personal";
import Complain from "../Pages/Admin/Complain";
import Teknisi from "../Pages/Admin/Teknisi";
import Customer from "../Pages/Admin/Customer";
import News from "../Pages/Admin/News";
import User from "../Pages/Admin/User";
import Admin from "../Pages/Admin/Admin";
import CetakLaporan from "../Pages/Admin/CetakLaporan";
const route = [
  {
    'path': '/',
    'element': <Home />
  },
  {
    'path': '/CetakLaporan',
    'element': <CetakLaporan />
  },
  {
    'path': '/Admin',
    'element': <Admin />
  },
  {
    'path': '/News',
    'element': <News />
  },
  {
    'path': '/Customer',
    'element': <Customer />
  },
  {
    'path': '/Tickets',
    'element': <Tickets />
  },
  {
    'path': '/Teknisi',
    'element': <Teknisi />
  },
  {
    'path': '/User',
    'element': <User />
  },
  {
    'path': '/Progress/:id_tiket',
    'element': <Progress />
  },
  {
    'path': '/Complain/:id_tiket',
    'element': <Complain />
  },
  {
    'path': '/Personal',
    'element': <Personal />
  }
];
export default route;