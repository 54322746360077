import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { del, put,get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement,SelectInput, Textinput, ImgMultiUpload } from "../../../../Components";
import { faClipboard,faComputer } from "@fortawesome/free-solid-svg-icons";

export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  let [prioritas, setPrioritas] = useState([]);
  const initialErrorMessage = {
    topik_problem: '',
    infrastruktur: '',
    prioritas: '',
    deskripsi_problem: ''
  }
  const readPrioritas = async () => {
    const req = await get("prioritas");
    setPrioritas(req.data.data);
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [prevList, setPrevList] = useState(Ref.oldFoto.current);
  const handleSubmit = async (e) => {
    if (loading) { return ""; }
    setLoading(true);
    let form = new FormData();
    form.append("prioritas", Ref.prioritas.current.value);
    form.append("infrastruktur", Ref.infrastruktur.current.value);
    form.append("deskripsi_problem", Ref.deskripsi_problem.current.data.get());
    form.append("topik_problem", Ref.topik_problem.current.value);
    Ref.foto.current.forEach((item, index) => {
      form.append("foto[]", item);
    })
    let req = await put('Customer/Tiket/'+Ref.id_tiket.current, form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      saveCallback();
      setPrevList([]);
      Ref.foto.current=[];
    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data.topik_problem) {
        setErrorMessage((n) => { return { ...n, topik_problem: req.data.data.topik_problem[0] } });
      }
      if (req.data.data.keterangan) {
        setErrorMessage((n) => { return { ...n, keterangan: req.data.data.keterangan[0] } });
      }
      if (req.data.data.deskripsi_problem) {
        setErrorMessage((n) => { return { ...n, deskripsi_problem: req.data.data.deskripsi_problem[0] } });
      }
    }
  }
  useEffect(() => {
    setPrevList(Ref.oldFoto.current);
    readPrioritas();
  }, [showModal])
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>Edit Tiket</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <SelectInput Ref={Ref.topik_problem} label="Topik Masalah" placeholder="Topik Masalah"
              icon={faClipboard} errorMessage={errorMessage.topik_problem} >
                <option value="-">--pilih topik masalah--</option>
              <option value="Permintaan Perbaikan Hardware">Permintaan Perbaikan Hardware</option>
              <option value="Perubahan SIMRS">Perubahan SIMRS</option>
            </SelectInput>
            <SelectInput Ref={Ref.infrastruktur} label="Infrastruktur"
              icon={faComputer} errorMessage={errorMessage.infrastruktur} >
              <option value="-">--pilih infrastruktur--</option>
              <option value="Jaringan">Jaringan</option>
              <option value="Komputer">Komputer</option>
              <option value="Printer">Printer</option>
              <option value="SIM RS">SIM RS</option>
            </SelectInput>
            <SelectInput Ref={Ref.prioritas} label="Prioritas" icon={faClipboard} errorMessage={errorMessage.prioritas}>
              {
                prioritas.map((item, index) => (
                  <option key={index} value={item.id_prioritas}>{item.prioritas}</option>
                ))
              }
            </SelectInput>
            <CKEditorElement
              label="Deskripsi"
              callBack={(editor) => { Ref.deskripsi_problem.current = editor }} />
            <div className="w-100 mt2">
              <ImgMultiUpload
                name="gambar_e"
                prevList={prevList}
                keyToDisplay="_foto"
                closeCallback={async (index) => {
                  if (Ref.oldFoto.current[index]!=undefined){
                    await del("Customer/Tiket/deleteGambar/" + Ref.oldFoto.current[index].id_gambar_tiket, null, token);
                    Ref.oldFoto.current = Ref.oldFoto.current.filter((item) => Ref.oldFoto.current.indexOf(item) != index)
                  }
                  Ref.foto.current = Ref.foto.current.filter((item) => Ref.foto.current.indexOf(item) != index)
                  setPrevList((n) => {
                    let nilaiLama = n;
                    return n.filter((item) => n.indexOf(item) != index);
                  })
                }}
                addCallback={(file) => {
                  setPrevList((n) => {
                    return [...n, URL.createObjectURL(file)];
                  });
                  Ref.foto.current.push(file);

                }}
                label="Gambar" />
            </div>
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}