import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { put, get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement, SelectInput, Textinput, ImgUpload, PasswordInput } from "../../../../Components";
import { faClipboard,faUser, faEnvelope, faMapMarker, faPhone, faUserCircle, faVenusMars, faNewspaper } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const [prev, setPrev] = useState(Ref.oldFoto.current);
  const initialErrorMessage = {
    topik: '',
    isi: '',
    foto: '',
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [alamat, setalamat] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) { return ""; }
    let form = new FormData();
    form.append("topik", Ref.topik.current.value);
    form.append("isi", Ref.isi.current.data.get());
    if (Ref.foto.current.files[0] != undefined) {
      form.append("foto", Ref.foto.current.files[0]);
    }
    let req = await put('Admin/News/editData/'+Ref.id_news.current, form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      saveCallback();

    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data.topik) {
        setErrorMessage((n) => { return { ...n, topik: req.data.data.topik[0] } });
      }
    }
  }
  useEffect(() => {
    setPrev(Ref.oldFoto.current);
    
  }, [showModal])
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>EDIT BERITA</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <Textinput Ref={Ref.topik} label="topik" placeholder="topik"
              icon={faNewspaper} errorMessage={errorMessage.topik} />
            <CKEditorElement
              label="Isi Berita"
              callBack={(data) => { Ref.isi.current = data }}
            />
            <div className="w-100 mt2">
              <ImgUpload
                name="gambar_e"
                Ref={Ref.foto}
                prev={prev}
                label="Gambar" />
            </div>
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}