import { AdminRoutes } from "../../../Routes";
import { Link, Route, Routes} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,  faHistory, faHome, faNewspaper, faPrint, faUser, faUserCheck, faUserTie, faWrench } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { HistoryItem, MenuItemMobile } from "../../../Components";
export default ({ userData, historyList, setHistoryList, setUserSetting, userSetting, setShowModal, history, page }) => {
  return (
    <>
      <div className="p2 pos-relative">
        <div className="row justify-content-space-between">
          <div style={{ boxShadow: "1px 1px 10px 3px" }} className="overflow-hidden pos-relative border-rad-5 m2 p1 justify-content-space-between w-100 display-flex align-items-center pl2 pt3 pb3">
            <button style={{ width: '50px', height: '50px' }} onClick={() => {setHistoryList(n => !n) }} className="display-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faHistory} size="2x" />
            </button>
            <h1 className="textcolor-black overflow-hidden" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{page}</h1>
            <button onClick={() => { setUserSetting(n => !n) }} className="display-flex align-items-center">
              <img src={userData.foto} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
          </div>
          {userSetting &&
            <div onClick={() => { setUserSetting(false) }} style={{ boxShadow: "1px 1px 10px 3px", top: '90px', right: '10px' }} className="overflow-hidden w-50 bg-white pos-absolute border-rad-5 p1 justify-content-space-between display-flex align-items-center pl2 pt3 pb3">
              <div className="w-100 row mt2 justify-content-space-arround align-items-center">
                <div className="w-100 display-block w-75 overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap' }}>
                  <span className="textcolor-black" style={{ fontSize: '16pt' }}>{userData.nama}</span>
                </div>
                <Link to="/Personal" className="btn-primary m1 p2 border-rad-10 w-100 text-center">
                  <span className="textcolor-black">User Setting</span>
                </Link>
                <button type="button" onClick={() => { setShowModal(true) }} className="w-100 display-block btn-danger m1 p2 border-rad-10">
                  <span className="textcolor-black">Logout</span>
                </button>
              </div>
            </div>}
          {historyList &&
            <div onClick={() => { setHistoryList(false) }} style={{ boxShadow: "1px 1px 10px 3px", top: '90px', left: '20px', maxHeight: '130px' }} className="overflow-auto w-50 bg-white pos-absolute border-rad-5 p1 justify-content-space-between display-flex pl2 pt3 pb3">
              <div className="w-100 row mt2 justify-content-space-arround align-items-center">
                {history.map((item, index) => (
                  <HistoryItem key={index} item={item} selected={page == item.id} />
                ))}
              </div>
            </div>}
        </div>
        <div className="row justify-content-space-between">
          <div style={{ boxShadow: "1px 1px 10px 3px", height: '450px', overflow: 'auto' }} className="border-rad-5 m2 p1 w-100">
            <Routes>
              {
                AdminRoutes.map((item, index) => (
                  <Route
                    key={index}
                    path={item.path}
                    element={item.element}
                  ></Route>
                ))
              }
            </Routes>
          </div>
        </div>
      </div>
      <div className="pos-absolute w-100 bg-white display-flex justify-content-center" style={{ bottom: "0px", fontSize: '10pt' }}>
        <MenuItemMobile selected={page == "Home"} to="/" icon={faHome} label="Home" />
        <MenuItemMobile selected={page == "Tickets"} to="Tickets" icon={faClipboard} label="Tickets" />
        <MenuItemMobile selected={page == "News"} to="News" icon={faNewspaper} label="News" />
        <MenuItemMobile selected={page == "Data Admin"} to="Admin" icon={faUserTie} label="Admin" />
        <MenuItemMobile selected={page == "Data Teknisi"} to="Teknisi" icon={faWrench} label="Teknisi" />
        <MenuItemMobile selected={page == "Data Customer"} to="Customer" icon={faUserCheck} label="Customer" />
        <MenuItemMobile selected={page == "Cetak Laporan"} to="CetakLaporan" icon={faPrint} label="Print" />
        <MenuItemMobile selected={page == "Data User"} to="User" icon={faUser} label="User" />
      </div>
    </>
  )
}
