import { useEffect, useState, useRef } from "react";
import { get } from "../../../../Libraries/Fetch";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import Options from "./Options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { SelectInput, Textinput } from "../../../../Components";
import ModalComplain from "./ModalComplain";
import parser from "html-react-parser";
export default function App({id_tiket}) {
  const token = useSelector(state => state.session.token);
  const dispatch = useDispatch();
  const [showModalComplain, setShowModalComplain] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageComponent, setPageComponent] = useState([])
  const [data, setData] = useState({
    data: [],
    maxPage: 1
  });
  const ModalData = {
    tableData:{
      search:useRef(null),
      page:useRef(1),
      limit:useRef(10)
    },
    complain: {
      id_complain: useRef(""),
      tgl:useRef(""),
      ket: useRef(""),
      foto:useRef([])
    }
  }
  const page = useRef(1);
  const readData = async () => {
    setLoading(true);
    let q = ModalData.tableData.search.current.value;
    let req = "";
    if (q != "") {
      req = await get("Admin/Complain/search/"+id_tiket+"/"+ page.current + "/" + ModalData.tableData.limit.current + "?q=" + q, token);
    }
    else {
      req = await get("Admin/Complain/readAll/" + id_tiket + "/" + page.current + "/" + ModalData.tableData.limit.current, token);
    }

    setData(req.data.data);
    setLoading(false);
    setPageComponent((n) => {
      let objek = {
        minPage: 1,
        maxPage: 1,
      };
      let pageComponentBaru = [];
      objek.minPage = page.current - 2;
      if (objek.minPage <= 0) {
        objek.minPage = 1;
      }
      if (page.current == 1) {
        objek.maxPage = page.current + 4;
      }
      else if (page.current == 2) {
        objek.maxPage = page.current + 3;
      }
      else if (page.current > 2) {
        objek.maxPage = page.current + 2;
      }
      if (objek.maxPage > req.data.data.maxPage) {
        objek.maxPage = req.data.data.maxPage;
      }
      for (let i = objek.minPage; i <= objek.maxPage; i++) {
        if (i == page.current) {
          pageComponentBaru.push(
            <button key={i} className="btn-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
        else {
          pageComponentBaru.push(
            <button key={i} onClick={async () => { page.current = i; await readData() }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
      }
      return pageComponentBaru;
    })
  }
  const readComplain = async (id_complain) => {
    let req = await get("Admin/Complain/readOne/" + id_complain, token);
    ModalData.complain.id_complain.current = id_complain;
    if (req.data.data != "") {
      ModalData.complain.tgl.current = req.data.data._tgl;
      ModalData.complain.ket.current = req.data.data.ket;
      ModalData.complain.foto.current = req.data.data._foto;
    }
    setShowModalComplain(true);
  }
  useEffect(() => {
    readData();
  }, [])
  return (
    <div className="m4">
      <ModalComplain
        showModal={showModalComplain}
        setShowModal={setShowModalComplain}
        Ref={ModalData.complain}
      />
      <>
        <div className="row w-100 mt2 justify-content-space-between align-items-center">
          <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
            <div className="display-flex align-items-center">
              <span>Show</span>
              <SelectInput
                onChange={async (e) => {
                  page.current = 1;
                  ModalData.tableData.limit.current = e.target.value;
                  await readData();
                }}
                className="display-block ml2 mr2"
                style={{ width: "45px" }}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </SelectInput>
              <span>Item</span>
            </div>
          </div>
          <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
            <Textinput placeholder="search"
              Ref={ModalData.tableData.search}
              onKeyDown={async (e) => {
                let q = ModalData.tableData.search.value
                if (e.key == "Enter") {
                  page.current = 1;
                  await readData();
                }
              }}
              icon={faSearch} />
          </div>
        </div>
        {
          loading
            ?
            <div className="row justify-content-center mt2">
              <Oval color="#007bff" height={50} width={50} />
            </div>
            :
            <div className="row mt2">
              <div className="w-100 mt2 table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Tgl. Complain</th>
                      <th>Keterangan</th>
                      <th>Control</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item._tgl}</td>
                            <td>{parser(item.ket)}</td>
                            <td>
                              <Options
                                item={item}
                                readComplain={readComplain}
                              />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
        }
        <div className="display-flex justify-content-flex-end w-100">
          <button onClick={() => { page.current = 1; readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {"|<"}
          </button>
          <button onClick={() => { page.current -= 1; if (page.current <= 0) { page.current = 1 } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {"<<"}
          </button>
          {pageComponent}
          <button onClick={() => { page.current += 1; if (page.current > data.maxPage) { page.current = data.maxPage } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {">>"}
          </button>
          <button onClick={() => { page.current = data.maxPage; readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {">|"}
          </button>
        </div>
      </>
    </div>
  )
}